.swiper {
  padding: 4px 0;
  height: 375px;
  z-index: 0;
}
.swiper-pagination-bullet {
  width: 70px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  border-radius: 4px;
  background-color: transparent;
  color: var(--blue-a18);
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background-color: var(--blue-a18);
  font-weight: 600;
  color: var(--blue-a3);
  opacity: 1;
}
